<template>
  <a-drawer
    class="test-main"
    title="审批详情"
    :body-style="{ paddingBottom: '80px' }"
    placement="right"
    width="700"
    :visible="visible"
    @close="onClose"
  >
    <a-spin :spinning="loading">
      <div class="task-info" v-if="formInfoData">
        <div class="task-form">
          <p class="form-name" style="margin-bottom: 12px">
            {{ formInfoData.processVariables.question }}
            <span
              class="pending-status"
              v-if="formInfoData.businessTaskStatus === 'PENDING'"
              >待审核</span
            >
            <span
              class="success-status"
              v-if="formInfoData.businessTaskStatus === 'PASSED'"
              >已通过</span
            >
            <span
              class="refuse-status"
              v-if="formInfoData.businessTaskStatus === 'REJECTED'"
              >已拒绝</span
            >
            <span
              class="cancel-status"
              v-if="formInfoData.businessTaskStatus === 'ORDER_CANCEL'"
              >订单已取消</span
            >
          </p>
          <div class="form-teacher">
            <img :src="defaultImg" alt="" />
            <span>申请人：{{ formInfoData.processVariables.startUserName }}</span>
            |
            <span>申请时间：{{ formInfoData.processVariables.createTime }}</span>
          </div>
        </div>
        <div class="line-wrap">
          <InfoTitle title="关联学生名单" />
          <a-button
            type="primary"
            ghost
            class="preview-detail-btn"
            @click="detailVisible = true"
            >查看明细</a-button
          >
        </div>
        <div class="apply-detail">
          <InfoTitle title="申请详情" />
          <div class="apply-detailContent">
            <div class="apply-item">
              <span class="apply-label">原辅导老师：</span
              ><span class="apply-content">{{
                formInfoData.processVariables.preClassAdminName || "-"
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">原辅导老师科目：</span
              ><span class="apply-content">{{
                SubjectEnumText[formInfoData.processVariables.subject || ""] || "-"
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">学员交接类型：</span>
              <span class="apply-content">
                {{ formInfoData.processVariables.handOverType }}
                <span
                  style="color: red"
                  v-if="
                    formInfoData.processVariables.realHandOverType &&
                    formInfoData.processVariables.handOverType !==
                      formInfoData.processVariables.realHandOverType
                  "
                  >（{{ formInfoData.processVariables.realHandOverType }}）</span
                >
              </span>
            </div>
            <div class="apply-item">
              <span class="apply-label">开始交接时间：</span>
              <span class="apply-content">{{
                formInfoData.processVariables.handOverStartTime || "-"
              }}</span>
            </div>
            <div class="apply-item">
              <span class="apply-label">备注：</span
              ><span class="apply-content">{{
                formInfoData.processVariables.remark || "-"
              }}</span>
            </div>
          </div>
        </div>

        <div class="apply-detail">
          <InfoTitle title="审批结果" />
          <div class="apply-detailContent" v-if="assigneesList.length > 0">
            <template>
              <MyProcessingRecords
                :class="subDetailType"
                :assigneesDatas="assigneesList"
              />
            </template>
          </div>
        </div>
        <a-modal
          title="交接反馈"
          width="800px"
          :visible="detailVisible"
          :cancelButtonProps="{ style: { display: 'none' } }"
          @cancel="detailVisible = false"
          @ok="detailVisible = false"
        >
          <a-space class="handover-info">
            <a-space v-if="!executeHandOver">
              <span>需交接学员数：{{ studentCount }}</span>
              <span>已绑定企微学员：{{ preStudentQwBoundedCount }}</span>
            </a-space>
            <a-space v-else>
              <span>学员交接成功数：{{ handOverSuccessCount }}/{{ studentCount }}</span>
              <span>学员企微交接成功数：{{ qwHandOverCount }}/{{ studentCount }}</span>
            </a-space>

            <a-button
              type="primary"
              ghost
              @click="handleDownloadExcel"
              :loading="downloadLoading"
              >下载表格</a-button
            >
          </a-space>
          <a-table
            :loading="tableLoading"
            :columns="columns"
            @change="handleTableChange"
            :data-source="tableData"
            :pagination="pagination"
            rowKey="uuid"
            v-if="detailVisible"
          ></a-table>
        </a-modal>
      </div>
    </a-spin>
  </a-drawer>
</template>

<script>
import { MyProcessingRecords } from "@wk/tch-sc";
import defaultImg from "@/assets/service_pic_head.png";
import InfoTitle from "@/components/WkInfoTitle/InfoTitle";
import { SubjectEnumText } from "@/enum/index";
import { getStudentHandoverInfo, downloadHandoverInfoExcel } from "@/api/headTeacher.js";

const INIT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50"], // 每页中显示的数据
  showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
};

export default {
  name: "DetailDrawer",
  components: { InfoTitle, MyProcessingRecords },
  watch: {
    detailVisible(v) {
      if (v) {
        this.handleGetStudentHandoverInfo();
      } else {
        this.pagination = INIT_PAGINATION;
      }
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    formInfoData: {
      type: Object,
      default: () => ({
        processVariables: {
          student: {},
          detail: {},
        },
      }),
    },
    assigneesList: {
      type: Array,
      default: () => [],
    },
    subDetailType: {
      type: String,
      default: "classAdmin",
    },
  },

  data() {
    return {
      qwHandOverStatusTextMap: {
        WAITING: "待交接",
        PENDING: "交接中",
        SUCCEEDED: "交接成功",
        FAILED: "交接失败",
      },
      studentCount: 0,
      preStudentQwBoundedCount: 0,
      handOverSuccessCount: 0,
      qwHandOverCount: 0,
      executeHandOver: false,
      tableData: [],
      tableLoading: false,
      downloadLoading: false,
      pagination: INIT_PAGINATION,
      SubjectEnumText,
      defaultImg,
      detailVisible: false,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 60,
          dataIndex: "num",
          customRender: (text, record, index) =>
            `${(this.pagination.current - 1) * this.pagination.pageSize + (index + 1)}`,
        },
        {
          title: "新辅导老师",
          dataIndex: "newClassAdminName",
        },
        {
          title: "科目",
          dataIndex: "subject",
          customRender: (text, record) => SubjectEnumText[record.subject] || "-",
        },
        {
          title: "学员",
          dataIndex: "studentName",
        },
        {
          title: "企微是否绑定",
          dataIndex: "studentQwBounded",
          customRender: (text, record) => {
            if (record.studentQwBounded == null) return "-";
            return record.studentQwBounded ? "是" : "否";
          },
        },
        {
          title: "学员是否交接成功",
          dataIndex: "classAdminHandOverSucceeded",
          customRender: (text, record) => {
            if (record.classAdminHandOverSucceeded == null) return "待交接";
            return record.classAdminHandOverSucceeded ? "是" : "否";
          },
        },
        {
          title: "企微交接状态",
          dataIndex: "qwHandOverStatus",
          customRender: (text, record) => {
            return this.qwHandOverStatusTextMap[record.qwHandOverStatus] || "-";
          },
        },
      ],
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    handleTableChange(data) {
      this.pagination = data;

      this.handleGetStudentHandoverInfo();
    },
    async handleGetStudentHandoverInfo() {
      this.tableLoading = true;

      const { data } = await getStudentHandoverInfo({
        processInstanceId: this.formInfoData.processInstanceId,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
      });
      this.tableLoading = false;

      this.studentCount = data.studentCount;
      this.preStudentQwBoundedCount = data.preStudentQwBoundedCount;
      this.handOverSuccessCount = data.handOverSuccessCount;
      this.qwHandOverCount = data.qwHandOverCount;
      this.executeHandOver = data.executeHandOver;

      this.tableData = data.handoverDetailPage.content;
      this.pagination.total = data.handoverDetailPage.totalCount;
    },
    async handleDownloadExcel() {
      // 8fe6ad4e-dbce-4a83-af12-f2f0bb2dd81a
      this.downloadLoading = true;
      try {
        const { data } = await downloadHandoverInfoExcel(
          this.formInfoData.processInstanceId
        );
        // 创建Blob对象
        const blob = new Blob([data]);

        // 创建下载链接
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        console.log(this.formInfoData.processVariables.preClassAdminName);

        downloadLink.download = `${this.formInfoData.processVariables.preClassAdminName}交接回执.xlsx`;

        // 触发下载
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // 清理
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(downloadLink.href);
      } catch (error) {
        console.error("下载文件失败:", error);
        this.$message.error("下载文件失败");
      } finally {
        this.downloadLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.test-main {
  .task-info {
    .task-form {
      .form-name {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          padding: 2px 10px;
          border-radius: 6px;
          margin: 0 10px;
        }
      }

      .pending-status {
        color: #ffbf00;
        background-color: rgba(255, 191, 0, 0.09);
      }
      .success-status {
        color: #00cf64;
        background-color: rgba(0, 207, 100, 0.09);
      }
      .refuse-status {
        color: #ff5353;
        background-color: rgba(255, 83, 83, 0.09);
      }
      .cancel-status {
        color: #454444;
        background-color: rgba(118, 114, 114, 0.09);
      }

      .form-teacher {
        font-size: 14px;
        color: #666666;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
          vertical-align: middle;
        }
        span {
          margin: 0 8px;
        }
      }
    }

    .line-wrap {
      margin-top: 20px;
      border-bottom: 1px solid #eee;
    }

    .preview-detail-btn {
      margin-bottom: 16px;
    }

    .apply-detail {
      margin-top: 24px;
      .apply-detailContent {
        .apply-item {
          margin-bottom: 16px;
          .apply-label {
            color: #666666;
          }
          .apply-content {
            color: #333333;
          }
        }
      }
    }
    .taskFormBtn {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      left: 0;
      background: #fff;
      border-radius: 0 0 4px 4px;
    }
  }
}
.handover-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
</style>
