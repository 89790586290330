<template>
  <div>
    <div class="task-nav">
      <div class="table-page-search-wrapper">
        <a-form layout="inline" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="申请人">
                <a-select
                  show-search
                  allowClear
                  @search="searchHeadmaster"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请搜索"
                  v-model="searchForm.applicantId"
                >
                  <a-select-option v-for="item in headmasterList" :key="item.uuid" :value="item.uuid">{{
                    item.fullName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="原辅导老师">
                <a-select
                  show-search
                  allowClear
                  @search="searchHeadmaster"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请搜索"
                  v-model="searchForm.preClassAdminId"
                >
                  <a-select-option v-for="item in headmasterList" :key="item.uuid" :value="item.uuid">{{
                    item.fullName
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="学员交接类型">
                <a-select
                  show-search
                  allowClear
                  @search="searchHeadmaster"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  v-model="searchForm.handOverType"
                >
                  <a-select-option v-for="item in handOverTypeOption" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="float: right; text-align: right">
              <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
                <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <div class="table-wrap">
      <div class="sel-navwrap">
        <div class="sel-left">
          <a-select
            v-model="selType"
            class="sel-task"
            :default-value="selTabData[0].value"
            style="width: 110px; font-weight: bold"
            @change="initSearchData('search')"
          >
            <a-select-option v-for="item in selTabData" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        :loading="showLoading"
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="pagination"
        rowKey="processInstanceId"
      >
        <template slot="finish" slot-scope="finish, record">
          <span>{{ initOptionsType(record.businessTaskStatus, approveStatusArr) }}</span>
        </template>

        <template slot="handOverType" slot-scope="handOverType, record">
          {{ handOverType }}
          <span
            style="color: red"
            v-if="record.processVariables.realHandOverType && handOverType !== record.processVariables.realHandOverType"
            >（{{ record.processVariables.realHandOverType }}）</span
          >
        </template>

        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)">查看详情</span>
        </template>
      </a-table>
    </div>

    <detail-drawer
      :visible="visible"
      :loading="detailLoading"
      :formInfoData="formInfoData"
      :assigneesList="assigneesList"
      :selType="selType"
      :subDetailType="subDetailType"
      @close="onClose"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { getHeadmasterList, queryStudentOrderStatus, feedBackDetail } from '@/api/headTeacher';
import DetailDrawer from './DetailDrawer.vue';

export default {
  name: 'classHourApproval',
  components: { DetailDrawer },
  data() {
    return {
      detailLoading: false,
      handOverTypeOption: [
        { label: '普通交接', value: '普通交接' },
        { label: '离职交接', value: '离职交接' },
      ],

      searchForm: {
        applicantId: undefined,
        preClassAdminId: undefined,
        handOverType: undefined,
      },

      showLoading: false,
      taskStatus: 'complate',
      options: {},
      assigneesList: [],
      operatevisible: false,
      subDetailType: 'classAdmin',
      userInfo: {},
      formInfoData: {
        processVariables: {
          student: {},
          detail: {},
        },
      },
      selType: 'PASSED',
      headmasterList: [],
      approveStatusArr: [
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
        { label: '已拒绝', value: 'REJECTED' },
      ],
      visible: false,
      selTabData: [
        { label: '我提交', value: 'OWN' },
        { label: '待审批', value: 'PENDING' },
        { label: '已审批', value: 'PASSED' },
      ],

      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) =>
            `${(this.pagination.current - 1) * this.pagination.pageSize + (index + 1)}`,
        },
        {
          title: '申请人',
          dataIndex: 'processVariables.startUserName',
          key: 'startUserName',
        },
        {
          title: '原辅导老师',
          dataIndex: 'processVariables.preClassAdminName',
          key: 'preClassAdminName',
        },
        {
          title: '申请时间',
          dataIndex: 'processVariables.createTime',
          key: 'createTime',
        },
        {
          title: '开始交接时间',
          dataIndex: 'processVariables.handOverStartTime',
          key: 'handOverStartTime',
        },
        {
          title: '学员交接类型',
          dataIndex: 'processVariables.handOverType',
          key: 'processVariables.handOverType',
          scopedSlots: { customRender: 'handOverType' },
        },
        {
          title: '审核状态',
          dataIndex: 'finish',
          key: 'finish',
          scopedSlots: { customRender: 'finish' },
        },
        {
          title: '审核人',
          ellipsis: true,
          dataIndex: 'processVariables.assigneeNames',
          key: 'processVariables.assigneeNames',
          customRender: (text) => {
            return text?.join('、') ?? '-';
          },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    };
  },
  methods: {
    moment,
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });

      return name;
    },

    initRole(datas) {
      return datas.map((item) => {
        let accounts = null;
        let taskFromAccount = null;
        if (item.accounts) {
          accounts = item.accounts.map((i) => {
            const rolesC = [];
            if (i && i.roles) {
              i.roles.forEach((k) => {
                rolesC.push(this.initOptionsType(k, this.options.userRole));
              });
            }
            return {
              ...i,
              rolesC: rolesC.join('/'),
            };
          });
        }
        if (item.taskFromAccount) {
          taskFromAccount = item.taskFromAccount;
          const taskFromAccountRoles = [];
          if (taskFromAccount.roles) {
            taskFromAccount.roles.forEach((k) => {
              taskFromAccountRoles.push(this.initOptionsType(k, this.options.userRole));
            });
          }

          taskFromAccount.rolesC = taskFromAccountRoles.join('/');
        }
        return {
          ...item,
          taskFromAccount,
          accounts,
        };
      });
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },

    onClose() {
      this.visible = false;
    },
    lookDetail(record) {
      const params = {
        processInstanceId: record.processInstanceId,
        taskId: record.id,
      };
      this.detailLoading = true;
      feedBackDetail(params).then((res) => {
        this.assigneesList = this.initRole(res.data.content.taskLogs);
        this.detailLoading = false;
      });

      this.formInfoData = record;

      this.visible = true;
    },
    searchHeadmaster(name) {
      if (!name) return;
      const params = {
        roles: [
          'ADMIN',
          'SUPER_ADMIN',
          'X_WUKONG',
          'HEAD_TEACHER_LEADER',
          'HEAD_TEACHER_SUPER',
          'CLASS_ADMIN',
          'TEACHING_STAFF',
          'TEACHING_STAFF_ADMIN',
        ],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.headmasterList = res.data.content;
      });
    },
    handleTableChange(data) {
      this.pagination.current = data.current;
      this.initSearchData();
    },

    resetCondition() {
      this.searchForm = {};
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.initSearchData('search');
    },
    initSearchData(act) {
      if (act === 'search') {
        this.pagination.current = 1;
      }
      this.showLoading = true;
      const params = {
        type: this.selType,
        categorySet: ['学员交接审批'],
        currentPage: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.searchForm,
      };

      queryStudentOrderStatus(params)
        .then((res) => {
          this.tableData = res.data.content;
          this.pagination.total = res.data.totalCount;
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },
  },
  mounted() {
    this.initSearchData();
  },
};
</script>

<style lang="less" scoped>
.operation {
  color: #3372fe;
  cursor: pointer;
}

/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 120px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.table-wrap {
  padding: 20px;
  background: #fff;
  min-height: calc(100vh - 200px);
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
